import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
@Injectable()
export class EnderecoService {
  baseUrl = environment.BASE_URL_CEP;

  constructor(private http: HttpClient) {}

  async findByCep(cep: string) {
    const httpOptions = {
      headers: new HttpHeaders()
        .append("Content-Type", "application/json")
        .append("Access-Control-Allow-Origin", "*")
        .append("Access-Control-Allow-Headers", "*")
        .append("Access-Control-Allow-Credentials", "true"),
    };

    const superagent = require("superagent");
    const response = await superagent.get(
      `${environment.BASE_URL_CEP}/${cep}/json`,
      httpOptions
    );

    return response.body;
  }
}
