import { CompaniesService } from "src/app/shared/services/companies.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ResponseZCore } from "../models/response-zcore";
import { OrmService } from "./orm.service";
import { lastValueFrom } from "rxjs";
import { StorageService } from "./storage.service";
import { UserProps } from "../types/user.types";

@Injectable()
export class UsuarioService {
  private currentUserSubject$ = new BehaviorSubject<UserProps>(null);
  currentUser$ = this.currentUserSubject$.asObservable();
  currentCompany;

  constructor(
    private http: HttpClient,
    private companiesService: CompaniesService
  ) {
    this.companiesService.currentCompanySubject$.subscribe((data) => {
      if (data !== null) {
        this.currentCompany = data;
      }
    });
  }

  login(usuario: any, senha: any): Observable<ResponseZCore> {
    return this.http.post<ResponseZCore>(environment.BASE_URL + "/sso/auth", {
      username: usuario,
      password: senha,
    });
  }

  async currentUser(): Promise<any> {
    const response: any = await lastValueFrom(
      this.http.get(`${environment.BASE_URL}/users/get-current-user`)
    );
    this.currentUserSubject$.next(response.data);

    if (!this.currentCompany) {
      if (response.data?.result?.empresaFavorita) {
        this.companiesService.changeCurrentCompany(
          response.data?.result?.empresaFavorita
        );
      }
    }
  }

  async getUsers(page: number, pageSize: number) {
    let url = `${environment.BASE_URL}/users/get-all-users?page=${page}&pageSize=${pageSize}`;

    return await lastValueFrom(this.http.get(url));
  }

  async updateUserStatus(userToUpdate: number) {
    return await lastValueFrom(
      this.http.patch(
        `${environment.BASE_URL}/users/update-user-status/${userToUpdate}`,
        null
      )
    );
  }
}
