import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject, debounceTime, distinctUntilChanged, map } from "rxjs";

interface Empresa {
  id: number;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  nomeFantasia: string;
  cnpjCpf: string;
  telefone: string;
  email: string;
  stAtivo: string;
  logo?: any;
  plano?: string;
}

@Component({
  selector: "vex-empresas-modal",
  templateUrl: "./clientes-modal.component.html",
  styleUrls: ["./clientes-modal.component.scss"],
})
export class ClientesModalComponent implements OnInit {
  clientes: any[] = [];
  filteredClientes: any[] = [];
  private filterText$ = new Subject<string>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ClientesModalComponent>
  ) {
    this.clientes = data.clientes;
    this.filteredClientes = this.clientes;
    this.filterText$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        map((text) => text.toLowerCase())
      )
      .subscribe((text) => {
        this.filteredClientes = this.clientes.filter((cliente) =>
          cliente.nome_fantasia.toLowerCase().includes(text)
        );
      });
  }

  ngOnInit(): void {}

  selectEmpresa(empresa: Empresa) {
    this.dialogRef.close(empresa);
  }

  filterBy(ev: any) {
    this.filterText$.next(ev.target.value);
  }
}
