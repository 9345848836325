import { ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { PopoverModule } from "../../../components/popover/popover.module";
import { MatTabsModule } from "@angular/material/tabs";
import { MatMenuModule } from "@angular/material/menu";
import { RelativeDateTimeModule } from "../../../pipes/relative-date-time/relative-date-time.module";
import { RouterModule } from "@angular/router";
import { MatRippleModule } from "@angular/material/core";
import { ToolbarShareComponent } from "./toolbar-share.component";
import { ToolbarShareDropdownComponent } from "./toolbar-share-dropdown/toolbar-share-dropdown.component";
import { MatInputModule } from "@angular/material/input";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@NgModule({
  declarations: [ToolbarShareComponent, ToolbarShareDropdownComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    PopoverModule,
    MatTabsModule,
    MatMenuModule,
    RelativeDateTimeModule,
    ReactiveFormsModule,
    RouterModule,
    MatRippleModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
  ],
  exports: [ToolbarShareComponent],
})
export class ToolbarShareModule {}
