import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { PopoverService } from "../../../components/popover/popover.service";
import { ToolbarShareDropdownComponent } from "./toolbar-share-dropdown/toolbar-share-dropdown.component";

@Component({
  selector: "vex-toolbar-share",
  templateUrl: "./toolbar-share.component.html",
  styleUrls: ["./toolbar-share.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarShareComponent implements OnInit {
  @ViewChild("originRef", { static: true, read: ElementRef })
  originRef: ElementRef;

  dropdownOpen: boolean;

  constructor(private popover: PopoverService, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  showPopover() {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarShareDropdownComponent,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: "center",
          originY: "top",
          overlayX: "center",
          overlayY: "bottom",
        },
        {
          originX: "end",
          originY: "bottom",
          overlayX: "end",
          overlayY: "top",
        },
      ],
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}
