<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div>
      <div class="dropdown-heading">Compartilhar Empresa</div>
      <div class="dropdown-subheading">
        Compartilhe a empresa {{ currentCompany.nome_fantasia }} com um novo
        usuário
      </div>
    </div>

    <!-- <button [matMenuTriggerFor]="settingsMenu" mat-icon-button type="button">
      <mat-icon
        class="notifications-header-icon"
        svgIcon="mat:settings"
      ></mat-icon>
    </button> -->
  </div>

  <div class="dropdown-content">
    <!-- <a
      *ngFor="let notification of notifications; trackBy: trackById"
      [class.read]="notification.read"
      [routerLink]="[]"
      class="notification flex items-center"
      matRipple
    >
      <mat-icon
        [ngClass]="notification.colorClass"
        [svgIcon]="notification.icon"
        class="notification-icon flex-none"
      ></mat-icon>
      <div class="flex-auto">
        <div class="notification-label">{{ notification.label }}</div>
        <div class="notification-description">
          {{ notification.datetime | relativeDateTime }}
        </div>
      </div>
      <mat-icon
        class="notification-chevron flex-none"
        svgIcon="mat:chevron_right"
      ></mat-icon>
    </a> -->
    <form
      class="flex items-center justify-between gap-1 p-4"
      [formGroup]="form"
    >
      <input
        formControlName="email"
        placeholder="email@dominio.com"
        matInput
        type="email"
        class="outline-none flex-1"
      />
      <button
        mat-flat-button
        color="primary"
        [disabled]="form.invalid"
        (click)="sendInvite()"
      >
        <span *ngIf="!loading">Enviar</span>
        <span *ngIf="loading" class="loading-animation">.</span>
      </button>
    </form>
  </div>

  <!-- <div class="dropdown-footer flex items-center justify-center">
    <button color="primary" mat-button type="button">MARCAR TUDO COMO LIDO</button>
  </div> -->
</div>

<!-- <mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon svgIcon="mat:clear_all"></mat-icon>
    <span>Marcar tudo como lido</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:notifications_off"></mat-icon>
    <span>Disabilitar notificações</span>
  </button>
</mat-menu> -->
