import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { CompaniesService } from "./companies.service";

@Injectable()
export class AuthService {
  constructor(
    private readonly http: HttpClient,
    private companyService: CompaniesService
  ) {}

  async login(body: { email: string; password: string }) {
    const response = await lastValueFrom(
      this.http.post(`${environment.BASE_URL}/auth/login`, body)
    );

    return response;
  }

  async register(body: { email: string; password: string }) {
    return await lastValueFrom(
      this.http.post(`${environment.BASE_URL}/auth/register`, body)
    );
  }

  async checkUserExists(body: { email: string }) {
    return await lastValueFrom(
      this.http.get(`${environment.BASE_URL}/auth/check-user/${body.email}`)
    );
  }

  async checkCode(body: { email: string; code: string }) {
    return await lastValueFrom(
      this.http.post(`${environment.BASE_URL}/auth/check-code`, body)
    );
  }

  async updatePassword(body: { email: string; password: string }) {
    return await lastValueFrom(
      this.http.put(`${environment.BASE_URL}/auth/update-password`, body)
    );
  }
}
