import { Platform } from "@angular/cdk/platform";
import { DOCUMENT } from "@angular/common";
import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from "@angular/core";
import {
  MatIconRegistry,
  SafeResourceUrlWithIconOptions,
} from "@angular/material/icon";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Settings } from "luxon";
import { ConfigService } from "../@vex/config/config.service";
import { NavigationService } from "../@vex/services/navigation.service";
import { SplashScreenService } from "../@vex/services/splash-screen.service";
import { LoadingService } from "./shared/services/loading.service";
import { CompaniesService } from "./shared/services/companies.service";

@Component({
  selector: "vex-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  loading: boolean;

  constructor(
    private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private companyService: CompaniesService
  ) {
    const isUserAdmin =
      localStorage.getItem("role") === "QURNSU4gUk9MRSBTWVNURU0=";

    this.loadingService.$loading.subscribe((loading) => {
      this.loading = loading;
    });

    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, "is-blink");
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case "mat":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case "logo":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case "flag":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    /**
     * Add your own routes here
     */
    this.navigationService.items = [
      {
        type: "subheading",
        label: "Dashboards",
        children: [
          {
            type: "link",
            label: "Início",
            route: "/",
            icon: "mat:insights",
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
      {
        type: "subheading",
        label: "Administrativo",
        children: [
          {
            type: "link",
            label: "Empresas",
            route: "/admin/empresa",
            icon: "mat:business_center",
          },
          {
            type: "link",
            label: "Dados da contabilidade",
            route: "/admin/dados-contabilidade",
            icon: "mat:balance",
          },
          {
            type: "link",
            label: "Operações fiscais",
            route: "/admin/operacoes-fiscais",
            icon: "mat:money",
          },
          {
            type: "link",
            label: "Serviços",
            route: "/admin/servico",
            icon: "mat:list_alt",
          },
        ],
      },
      {
        type: "subheading",
        label: "Configuração",
        children: [
          {
            type: "link",
            label: "Configuração de Notas",
            route: "/config/config-notas",
            icon: "mat:settings",
          },
        ],
      },
      {
        type: "subheading",
        label: "Operacional",
        children: [
          {
            type: "link",
            label: "Clientes",
            route: "/operacional/clientes",
            icon: "mat:group",
          },
          {
            type: "link",
            label: "Notas fiscais",
            route: "/operacional/nota-fiscal",
            icon: "mat:poll",
          },
        ],
      },
    ];

    isUserAdmin &&
      this.navigationService.items.splice(1, 0, {
        type: "subheading",
        label: "ERP - Administração",
        children: [
          {
            type: "link",
            label: "Usuários",
            route: "/erp-admin/usuarios",
            icon: "mat:admin_panel_settings",
          },
        ],
      });
  }

  async ngOnInit() {
    const loginRoute =
      window.location.href.includes("login") ||
      window.location.href.includes("register");

    if (!loginRoute) {
      await this.companyService.getCurrentFavorited();
    }
  }
}
