import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ResponseZCore } from "../models/response-zcore";
import { Observable } from "rxjs";
import { debug } from "console";

@Injectable()
export class OrmService {
  baseUrl = "https://core4you.tech4u.com.br/v1";

  constructor(private http: HttpClient) {}

  get(
    schema: string,
    filter: any,
    sort: any,
    populate: any = {},
    tenant: any = null,
    limit: number = 1000,
    skip: number = 0,
    select: any = {}
  ) {
    return this.http.post<ResponseZCore>(this.baseUrl + "/orm/find", {
      schema,
      tenant,
      filter,
      select: {},
      sort,
      limit,
      skip,
      populate,
      secondsCache: 1,
    });
  }

  getOne(
    schema: string,
    filter: any,
    sort: any,
    populate: any = {},
    tenant: any = null
  ) {
    return this.http.post<ResponseZCore>(this.baseUrl + "/orm/find-one", {
      schema,
      tenant,
      filter,
      select: {},
      sort,
      populate,
      secondsCache: 1,
    });
  }

  getById(
    schema: string,
    id: string,
    select: any = {},
    populate: any = {},
    tenant: any = null
  ) {
    return this.http.post<ResponseZCore>(this.baseUrl + "/orm/find-by-id", {
      schema,
      tenant,
      _id: id,
      select,
      populate,
      secondsCache: 1,
    });
  }

  count(schema: string, filter: any) {
    return this.http.post<ResponseZCore>(this.baseUrl + "/orm/count", {
      schema,
      filter,
      secondsCache: 1,
    });
  }

  insert(schema: string, data: any) {
    return this.http.post<ResponseZCore>(this.baseUrl + "/orm/insert", {
      schema,
      data,
    });
  }

  insertTenant(schema: string, data: any, tenant: any = null) {
    return this.http.post<ResponseZCore>(this.baseUrl + "/orm/insert", {
      schema,
      tenant,
      data,
    });
  }

  updateTenant(schema: string, id: any, data: any, tenant: any = null) {
    return this.http.put<ResponseZCore>(this.baseUrl + "/orm/update", {
      schema,
      _id: id,
      tenant,
      data,
    });
  }

  deleteTenant(schema: string, id: any, tenant: any = null) {
    return this.http.put<ResponseZCore>(this.baseUrl + "/orm/delete", {
      schema,
      _id: id,
      tenant,
    });
  }
}
