import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

    constructor() {
    }

    async set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    async get(key: string) {
        return JSON.parse(localStorage.getItem(key));
    }

    async remove(key: string) {
        return localStorage.removeItem(key);
    }
}
