import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class UtilsService {
  constructor(private snackbar: MatSnackBar) {}

  showSnackbar(message: string, type: "success" | "error" | "warning") {
    return this.snackbar.open(message, undefined, {
      duration: 3000,
      verticalPosition: "top",
      horizontalPosition: "right",
      panelClass: this.getSnackbarPannel(type),
    });
  }

  private getSnackbarPannel(type: "success" | "error" | "warning") {
    switch (type) {
      case "success":
        return ["success-snackbar"];
      case "error":
        return ["error-snackbar"];
      case "warning":
        return ["warning-snackbar"];
    }
  }

  getFormattedCurrency(value: number) {
    let formatter = Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    return formatter.format(value);
  }
}
