import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { StorageService } from "src/app/shared/services/storage.service";
import { PopoverRef } from "../popover/popover-ref";

@Component({
  selector: "vex-user-menu",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"],
})
export class UserMenuComponent implements OnInit {
  constructor(
    private readonly popoverRef: PopoverRef,
    private storageService: StorageService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  close(): void {
    /** Wait for animation to complete and then close */
    setTimeout(() => this.popoverRef.close(), 250);
  }

  async logout() {
    localStorage.clear();
    await this.router.navigateByUrl("/login");
    setTimeout(() => this.popoverRef.close(), 250);
  }

  goToPage(page: string) {
    this.router.navigateByUrl(page);
    setTimeout(() => this.popoverRef.close(), 250);
  }
}
