<div class="-m-6 overflow-y-scroll rounded-md">
  <div class="flex items-center gap-4 px-6 py-3 border-b border-divider">
    <mat-icon svgIcon="mat:search" class="text-secondary flex-none"></mat-icon>
    <input
      type="text"
      placeholder="Pesquisar..."
      class="text-xl font-medium bg-transparent outline-none flex-auto placeholder-secondary"
      (input)="filterBy($event)"
    />
    <!-- <button
      class="flex-none ltr:-mr-2 rtl:-ml-2 text-secondary"
      type="button"
      mat-icon-button
    >
      <mat-icon svgIcon="mat:settings"></mat-icon>
    </button> -->
  </div>

  <div class="p-4">
    <div class="text-xs font-semibold text-secondary px-2 mb-2">Clientes</div>

    <div class="space-y-1">
      <div
        class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
        matRipple
        *ngFor="let cliente of filteredClientes"
        (click)="selectEmpresa(cliente)"
      >
        <div class="flex-auto text-base font-medium">
          {{ cliente.nome_fantasia }}
        </div>
        <div
          class="flex-none text-xs text-secondary font-medium flex items-center gap-2"
        >
          <mat-icon
            svgIcon="mat:person_outline"
            class="icon-sm flex-none"
          ></mat-icon>
        </div>
        <mat-icon
          svgIcon="mat:chevron_right"
          class="icon-sm flex-none"
        ></mat-icon>
      </div>
    </div>
  </div>
</div>
