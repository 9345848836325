<div
  [class.container]="isVerticalLayout$ | async"
  class="toolbar w-full px-gutter flex items-center"
>
  <button
    (click)="openSidenav()"
    [class.hidden]="!mobileQuery"
    mat-icon-button
    type="button"
    color="primary"
  >
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a
    *ngIf="isVerticalLayout$ | async"
    [routerLink]="['/']"
    class="ltr:mr-4 rtl:ml-4 block flex items-center"
  >
    <img
      alt="Logo"
      class="w-32 select-none"
      src="assets/img/logo/logo-tech.jpg"
    />
    <h1
      [class.hidden]="!mobileQuery"
      class="title ltr:pl-4 rtl:pr-4 m-0 select-none"
    >
      VEX
    </h1>
  </a>

  <button
    *ngIf="isHorizontalLayout$ | async"
    [class.hidden]="mobileQuery"
    [matMenuTriggerFor]="addNewMenu"
    color="primary"
    mat-flat-button
    type="button"
  >
    Atalhos de cadastro
  </button>

  <mat-menu
    #addNewMenu="matMenu"
    [overlapTrigger]="false"
    xPosition="after"
    yPosition="below"
  >
    <button mat-menu-item (click)="goToPage('/admin/empresa/cadastro')">
      <mat-icon svgIcon="mat:business_center"></mat-icon>
      <span>Cadastrar Empresa</span>
    </button>
    <button
      mat-menu-item
      (click)="goToPage('/admin/empresa/operacoes-fiscais/cadastro')"
    >
      <mat-icon svgIcon="mat:money"></mat-icon>
      <span>Cadastrar Operação fiscal</span>
    </button>
    <button mat-menu-item (click)="goToPage('/admin/servico/novo')">
      <mat-icon svgIcon="mat:list_alt"></mat-icon>
      <span>Cadastrar Serviço</span>
    </button>
    <button mat-menu-item (click)="goToPage('/config/config-notas/novo')">
      <mat-icon svgIcon="mat:settings"></mat-icon>
      <span>Configurar Notas fiscais</span>
    </button>
    <button mat-menu-item (click)="goToPage('/operacional/cliente/novo')">
      <mat-icon svgIcon="mat:group"></mat-icon>
      <span>Cadastrar Cliente</span>
    </button>
    <button mat-menu-item (click)="goToPage('/operacional/nota-fiscal/emitir')">
      <mat-icon svgIcon="mat:poll"></mat-icon>
      <span>Emitir nota fiscal</span>
    </button>

    <!-- <button [matMenuTriggerFor]="documentMenu" mat-menu-item>
      <mat-icon svgIcon="mat:ballot"></mat-icon>
      <span>Emitir nota fiscal</span>
    </button> -->
  </mat-menu>

  <!-- <mat-menu #documentMenu="matMenu">
    <button mat-menu-item>
      <mat-icon svgIcon="mat:description"></mat-icon>
      <span>Emprestimos</span>
    </button>
  </mat-menu> -->

  <!-- <div #megaMenuOriginRef class="ltr:ml-2 rtl:mr-2">
    <button *ngIf="isHorizontalLayout$ | async"
            [class.hidden]="mobileQuery"
            (click)="openMegaMenu(megaMenuOriginRef)"
            [ngClass]="{ 'bg-primary/10': megaMenuOpen$ | async }"
            color="primary"
            mat-button
            type="button">
      <span>Mega Menu</span>
      <mat-icon class="icon-sm" svgIcon="mat:arrow_drop_down"></mat-icon>
    </button>
  </div> -->

  <div
    *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
    [class.hidden]="mobileQuery"
    class="px-gutter flex-none flex items-center"
  >
    <vex-navigation-item
      *ngFor="let item of navigationItems"
      [item]="item"
    ></vex-navigation-item>
  </div>

  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center">
    <!-- <div class="px-1">
      <button (click)="openSearch()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:search"></mat-icon>
      </button>
    </div>-->
    <!--
    <div class="px-1">
      <vex-toolbar-notifications></vex-toolbar-notifications>
    </div> -->
    <div class="px-1">
      <vex-toolbar-share></vex-toolbar-share>
    </div>
    <div
      class="h-[32px] w-[2px] mx-4"
      style="background-color: rgb(var(--color-primary))"
    ></div>
    <div class="px-1 flex items-center gap-4">
      <div
        class="w-10 h-10 rounded-sm flex items-center justify-center"
        style="background-color: rgb(var(--color-primary))"
      >
        <span class="font-semibold text-lg">{{
          currentCompany?.nome_fantasia?.charAt(0).toUpperCase()
        }}</span>
      </div>
      <div class="flex flex-col">
        <h3 style="color: rgb(var(--color-primary))" class="text-xs">
          Empresa Atual:
        </h3>
        <h3
          style="color: rgb(var(--color-primary))"
          class="text-sm font-semibold"
        >
          {{ currentCompany?.nome_fantasia }}
        </h3>
      </div>
    </div>
    <!-- <div class="px-1">
      <button
        (click)="openQuickpanel()"
        type="button"
        mat-icon-button
        color="primary"
      >
        <mat-icon color="light" svgIcon="mat:share"></mat-icon>
      </button>
    </div> -->

    <div *ngIf="userVisible$ | async" class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>
  </div>
</div>

<vex-navigation
  *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
  [class.hidden]="mobileQuery"
></vex-navigation>
