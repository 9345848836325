import { ClientesService } from "./shared/services/clientes.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, LOCALE_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxLoadingModule } from "ngx-loading";
import { ngxLoadingAnimationTypes } from "ngx-loading";
import { VexModule } from "../@vex/vex.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CustomLayoutModule } from "./custom-layout/custom-layout.module";
import { AuthGuardService } from "./shared/services/auth-guard.service";
import { EnderecoService } from "./shared/services/endereco.service";
import { LoadingService } from "./shared/services/loading.service";
import { OrmService } from "./shared/services/orm.service";
import { StorageService } from "./shared/services/storage.service";
import { UsuarioService } from "./shared/services/usuario.service";
import { AuthInterceptor } from "./shared/interceptors/auth.interceptor";
import { ClientesModalComponent } from "./nota-fiscal/clientes-modal/clientes-modal.component";
import { MatIconModule } from "@angular/material/icon";
import { AuthService } from "./shared/services/auth.service";
import { UtilsService } from "./shared/services/utils.service";
import localePt from "@angular/common/locales/pt";
import { registerLocaleData } from "@angular/common";

registerLocaleData(localePt);
import { SocketIoConfig, SocketIoModule } from "ngx-socket-io";
import { environment } from "src/environments/environment";
import { NgxMaskModule } from "ngx-mask";

const config: SocketIoConfig = {
  url: environment.BASE_URL_SOCKET,
  options: {
    transports: ["websocket"],
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
  },
};

@NgModule({
  declarations: [AppComponent, ClientesModalComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    VexModule,
    CustomLayoutModule,
    MatIconModule,
    NgxMaskModule.forRoot(),
    SocketIoModule.forRoot(config),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: "rgba(0,0,0,0.5)",
      backdropBorderRadius: "4px",
      primaryColour: "#ffffff",
      secondaryColour: "#ffffff",
      tertiaryColour: "#ffffff",
      fullScreenBackdrop: true,
    }),
  ],
  providers: [
    StorageService,
    AuthGuardService,
    UsuarioService,
    AuthService,
    UtilsService,
    LoadingService,
    EnderecoService,
    OrmService,
    ClientesService,
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
