<button
  #originRef
  (click)="showPopover()"
  [class.bg-hover]="dropdownOpen"
  class="button"
  mat-icon-button
  type="button"
>
  <mat-icon color="primary" svgIcon="mat:share"></mat-icon>
</button>
