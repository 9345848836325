import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private storageService: StorageService) {}

  // @ts-ignore
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (
      (await this.storageService.get("erp-token")) &&
      (await this.storageService.get("erp-user"))
    ) {
      return true;
    }
    await this.storageService.remove("erp-token");
    await this.storageService.remove("erp-user");

    await this.router.navigate(["login"]);
    return false;
  }
}
