import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CompaniesService {
  baseUrl = environment.BASE_URL;

  companiesCountSubject: BehaviorSubject<number> = new BehaviorSubject(null);
  companiesCount$ = this.companiesCountSubject.asObservable();

  private currentFavoritedCompanySubject$ = new BehaviorSubject(null);
  currentFavoritedCompany$ =
    this.currentFavoritedCompanySubject$.asObservable();

  currentCompanySubject$ = new BehaviorSubject(null);
  currentCompany$ = this.currentCompanySubject$.asObservable();

  constructor(private http: HttpClient) {}

  updateCurrentFavoritedCompanySubject(company: any) {
    // this.currentFavoritedCompanySubject$.next(company);
  }

  async getAll(page: number, limit: number) {
    const params = new HttpParams()
      .set("page", page.toString())
      .set("pageSize", limit.toString());

    return await lastValueFrom(
      this.http.get(`${this.baseUrl}/companies`, { params })
    );
  }

  async getAllWith() {
    const response = await lastValueFrom(
      this.http.get(`${this.baseUrl}/companies/all`)
    );
    return response;
  }

  async getCount() {
    const response: any = await lastValueFrom(
      this.http.get(`${this.baseUrl}/companies/count`)
    );

    this.companiesCountSubject.next(response.data);
  }

  async getById(id: number) {
    return await lastValueFrom(
      this.http.get(`${this.baseUrl}/companies/get-one/${id}`)
    );
  }

  async delete(id: number) {
    return await lastValueFrom(
      this.http.delete(`${this.baseUrl}/delete/${id}`)
    );
  }

  async post(company: any, logoFile: File | null) {
    const formData = new FormData();
    formData.append("file", logoFile);
    formData.append("company", JSON.stringify(company));

    return await lastValueFrom(
      this.http.post(`${this.baseUrl}/companies`, formData)
    );
  }

  async getStates() {
    return await lastValueFrom(
      this.http.get(`${this.baseUrl}/companies/states`)
    );
  }

  async getCities(stateId: number) {
    return await lastValueFrom(
      this.http.get(`${this.baseUrl}/companies/cities/${stateId}`)
    );
  }

  async update(companyId: number, newCompany: any, logoFile: File = null) {
    const formData = new FormData();
    formData.append("file", logoFile);
    formData.append("company", JSON.stringify(newCompany));

    return await lastValueFrom(
      this.http.put(`${this.baseUrl}/companies/update/${companyId}`, formData)
    );
  }

  async favoriteCompany(companyId: number) {
    return await lastValueFrom(
      this.http.put(`${this.baseUrl}/companies/favorite`, {
        empresa_id: companyId,
      })
    );
  }

  changeCurrentCompany(company: any) {
    this.currentCompanySubject$.next(company);
  }

  async getCurrentFavorited() {
    const response: any = await lastValueFrom(
      this.http.get(`${this.baseUrl}/companies/get-current-favorited-by-user`)
    );

    this.currentFavoritedCompanySubject$.next(response.data);
  }

  async generateToken(companyId: number) {
    return await lastValueFrom(
      this.http.get(`${this.baseUrl}/companies/generate-token/${companyId}`)
    );
  }

  async shareCompany(body: { email: string; empresa_id: number }) {
    return await lastValueFrom(
      this.http.post(`${this.baseUrl}/companies/share-company`, body)
    );
  }
}
