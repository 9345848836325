import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { UsuarioService } from "../services/usuario.service";
import { UserProps } from "../types/user.types";
import { CompaniesService } from "../services/companies.service";

@Injectable({
  providedIn: "root",
})
export class FirstAccessGuard implements CanActivate {
  currentUser: any;
  currentCompany: any;

  constructor(
    private readonly usersService: UsuarioService,
    private readonly router: Router
  ) {
    usersService.currentUser$.subscribe((user) => {
      if (user !== null) {
        this.currentUser = user;
      }
    });
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this.usersService.currentUser();

    if (!this.currentUser?.result?.empresas?.length) {
      this.router.navigate(["admin/empresa/cadastro"]);
      return false;
    }
    return true;
  }
}
