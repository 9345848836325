import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, throwError } from "rxjs";
import { CompaniesService } from "../services/companies.service";
import { UtilsService } from "../services/utils.service";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  currentCompany: any;
  constructor(
    private companyService: CompaniesService,
    private readonly router: Router,
    private readonly utilsService: UtilsService
  ) {
    companyService.currentCompanySubject$.subscribe((data) => {
      if (data !== null) {
        this.currentCompany = data;
      }
    });
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const arrayEndpointsAuth = ["login"];

    const token = localStorage.getItem("access_token");

    const splitUrl = request.url.split("/");
    const lastSegment = splitUrl[splitUrl.length - 1];

    if (token && !arrayEndpointsAuth.includes(lastSegment)) {
      let headers = request.headers.set("Authorization", "Bearer " + token);

      if (this.currentCompany) {
        headers = headers.set(
          "currentCompanyId",
          this.currentCompany?.id.toString()
        );
      }

      request = request.clone({ headers });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (
          (err.status === 401 && this.router.url !== "/login") ||
          (err.status === 401 && this.router.url !== "/register")
        ) {
          this.utilsService.showSnackbar(
            "Sessão expirada, faça login novamente.",
            "error"
          );

          localStorage.clear();
          this.router.navigate(["/login"]);
        }
        return throwError(() => err);
      })
    );
  }
}
