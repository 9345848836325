import { EditarClienteRoutingModule } from "./cliente/editar-cliente/editar-cliente-routing.module";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CustomLayoutComponent } from "./custom-layout/custom-layout.component";
import { VexRoutes } from "../@vex/interfaces/vex-route.interface";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { AuthGuardService } from "./shared/services/auth-guard.service";
import { FirstAccessGuard } from "./shared/guards/first-access.guard";

const routes: VexRoutes = [
  {
    path: "login",
    loadChildren: () =>
      import("./auth/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "register",
    loadChildren: () =>
      import("./auth/register/register.module").then((m) => m.RegisterModule),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./auth/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      ),
  },
  // {
  //   path: 'forgot-password',
  //   loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  // },
  // {
  //   path: 'coming-soon',
  //   loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  // },
  {
    path: "",
    component: CustomLayoutComponent,
    children: [
      {
        path: "home",
        redirectTo: "/",
        pathMatch: "full",
      },
      {
        path: "",
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomeModule),
        canActivate: [FirstAccessGuard],
      },
      {
        path: "erp-admin",
        children: [
          {
            path: "usuarios",
            loadChildren: () =>
              import("./usuarios/listagem/listagem.module").then(
                (m) => m.ListagemModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
            canActivate: [FirstAccessGuard],
          },
        ],
      },
      {
        path: "admin",
        children: [
          {
            path: "empresa",
            loadChildren: () =>
              import("./empresa/listagem/listagem-empresa.module").then(
                (m) => m.ListagemModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
            canActivate: [FirstAccessGuard],
          },
          {
            path: "empresa/cadastro",
            loadChildren: () =>
              import("./empresa/cadastro/cadastro.module").then(
                (m) => m.CadastroModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
          },
          {
            path: "empresa/editar/:id",
            loadChildren: () =>
              import("./empresa/editar-empresa/editar-empresa.module").then(
                (m) => m.EditarEmpresaModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
            canActivate: [FirstAccessGuard],
          },
          {
            path: "dados-contabilidade",
            loadChildren: () =>
              import("./dados-contabilidade/listagem.module").then(
                (m) => m.ListagemModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
            canActivate: [FirstAccessGuard],
          },
          {
            path: "operacoes-fiscais",
            loadChildren: () =>
              import("./operacoes-fiscais/listagem/listagem.module").then(
                (m) => m.ListagemModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
          },
          {
            path: "operacoes-fiscais/cadastro",
            loadChildren: () =>
              import("./operacoes-fiscais/cadastro/cadastro.module").then(
                (m) => m.CadastroModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
          },
          {
            path: "operacoes-fiscais/editar",
            loadChildren: () =>
              import("./operacoes-fiscais/editar/editar.module").then(
                (m) => m.EditarModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
          },
          {
            path: "servico",
            loadChildren: () =>
              import("./servico/listagem/listagem.module").then(
                (m) => m.ListagemModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
            canActivate: [FirstAccessGuard],
          },
          {
            path: "servico/novo",
            loadChildren: () =>
              import("./servico/cadastro-update/cadastro.module").then(
                (m) => m.CadastroModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
            canActivate: [FirstAccessGuard],
          },
        ],
      },
      {
        path: "config",
        children: [
          {
            path: "config-notas",
            loadChildren: () =>
              import("./config-notas/listagem/listagem.module").then(
                (m) => m.ListagemModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
            canActivate: [FirstAccessGuard],
          },
          {
            path: "config-notas/novo",
            loadChildren: () =>
              import("./config-notas/cadastro-update/cadastro.module").then(
                (m) => m.CadastroModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
            canActivate: [FirstAccessGuard],
          },
        ],
      },
      {
        path: "operacional",
        children: [
          {
            path: "clientes",
            loadChildren: () =>
              import("./cliente/listagem/listagem.module").then(
                (m) => m.ListagemModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
            canActivate: [FirstAccessGuard],
          },
          {
            path: "cliente/novo",
            loadChildren: () =>
              import("./cliente/cadastro/cadastro.module").then(
                (m) => m.CadastroModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
            canActivate: [FirstAccessGuard],
          },
          {
            path: "cliente/editar/:id",
            loadChildren: () =>
              import("./cliente/editar-cliente/editar-cliente.module").then(
                (m) => m.EditarClienteModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
            canActivate: [FirstAccessGuard],
          },
          {
            path: "nota-fiscal",
            loadChildren: () =>
              import("./nota-fiscal/listagem/listagem.module").then(
                (m) => m.ListagemModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
            canActivate: [FirstAccessGuard],
          },

          {
            path: "nota-fiscal/emitir",
            loadChildren: () =>
              import("./nota-fiscal/create-update-nota/emissao.module").then(
                (m) => m.EmissaoModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
            canActivate: [FirstAccessGuard],
          },
          {
            path: "nota-fiscal/emitir",
            loadChildren: () =>
              import("./nota-fiscal/create-update-nota/emissao.module").then(
                (m) => m.EmissaoModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
            canActivate: [FirstAccessGuard],
          },

          {
            path: "change-company",
            loadChildren: () =>
              import("./change-company/change-company.module").then(
                (m) => m.ChangeCompanyModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
            canActivate: [FirstAccessGuard],
          },
        ],
      },

      // {
      //   path: 'apps',
      //   children: [
      //     {
      //       path: 'chat',
      //       loadChildren: () => import('./pages/apps/chat/chat.module').then(m => m.ChatModule),
      //       data: {
      //         toolbarShadowEnabled: true
      //       }
      //     },
      //     {
      //       path: 'mail',
      //       loadChildren: () => import('./pages/apps/mail/mail.module').then(m => m.MailModule),
      //       data: {
      //         toolbarShadowEnabled: true,
      //         scrollDisabled: true
      //       }
      //     },
      //     {
      //       path: 'social',
      //       loadChildren: () => import('./pages/apps/social/social.module').then(m => m.SocialModule)
      //     },
      //     {
      //       path: 'contacts',
      //       loadChildren: () => import('./pages/apps/contacts/contacts.module').then(m => m.ContactsModule)
      //     },
      //     {
      //       path: 'calendar',
      //       loadChildren: () => import('./pages/apps/calendar/calendar.module').then(m => m.CalendarModule),
      //       data: {
      //         toolbarShadowEnabled: true
      //       }
      //     },
      //     {
      //       path: 'aio-table',
      //       loadChildren: () => import('./pages/apps/aio-table/aio-table.module').then(m => m.AioTableModule),
      //     },
      //     {
      //       path: 'help-center',
      //       loadChildren: () => import('./pages/apps/help-center/help-center.module').then(m => m.HelpCenterModule),
      //     },
      //     {
      //       path: 'scrumboard',
      //       loadChildren: () => import('./pages/apps/scrumboard/scrumboard.module').then(m => m.ScrumboardModule),
      //     },
      //     {
      //       path: 'editor',
      //       loadChildren: () => import('./pages/apps/editor/editor.module').then(m => m.EditorModule),
      //     },
      //   ]
      // },
      // {
      //   path: 'pages',
      //   children: [
      //     {
      //       path: 'pricing',
      //       loadChildren: () => import('./pages/pages/pricing/pricing.module').then(m => m.PricingModule)
      //     },
      //     {
      //       path: 'faq',
      //       loadChildren: () => import('./pages/pages/faq/faq.module').then(m => m.FaqModule)
      //     },
      //     {
      //       path: 'guides',
      //       loadChildren: () => import('./pages/pages/guides/guides.module').then(m => m.GuidesModule)
      //     },
      //     {
      //       path: 'invoice',
      //       loadChildren: () => import('./pages/pages/invoice/invoice.module').then(m => m.InvoiceModule)
      //     },
      //     {
      //       path: 'error-404',
      //       loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      //     },
      //     {
      //       path: 'error-500',
      //       loadChildren: () => import('./pages/pages/errors/error-500/error-500.module').then(m => m.Error500Module)
      //     }
      //   ]
      // }
    ],
    // canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "corrected",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule, QuicklinkModule],
})
export class AppRoutingModule {}
