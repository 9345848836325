<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div class="flex items-center">
      <div class="dropdown-heading-icon flex items-center justify-center">
        <mat-icon svgIcon="mat:person"></mat-icon>
      </div>
      <div class="dropdown-heading">Wunilberto Melo</div>
    </div>

    <!-- <button
      [matMenuTriggerFor]="settingsMenu"
      mat-icon-button
      matTooltip="Settings"
      matTooltipPosition="before"
      type="button"
    >
      <mat-icon
        class="notifications-header-icon"
        svgIcon="mat:settings"
      ></mat-icon>
    </button> -->
  </div>

  <!-- <div class="dropdown-content">
    <a (click)="close()"
       *ngFor="let item of items; trackBy: trackById"
       [routerLink]="item.route"
       class="notification flex items-center"
       matRipple>
      <mat-icon [svgIcon]="item.icon"
                [ngClass]="item.colorClass"
                class="notification-icon flex-none"></mat-icon>
      <div class="flex-auto">
        <div class="notification-label">{{ item.label }}</div>
        <div class="notification-description">{{ item.description }}</div>
      </div>
      <mat-icon class="notification-chevron flex-none" svgIcon="mat:chevron_right"></mat-icon>
    </a>
  </div> -->

  <div class="dropdown-footer flex items-center justify-between">
    <!-- <button [matMenuTriggerFor]="statusMenu" class="dropdown-footer-select" mat-button type="button">
      <ng-container *ngFor="let status of statuses; trackBy: trackById">
        <span *ngIf="status === activeStatus">
          <mat-icon [ngClass]="status.colorClass" [svgIcon]="status.icon"></mat-icon>
          <span>{{ status.label }}</span>
          <mat-icon class="dropdown-footer-select-caret" svgIcon="mat:arrow_drop_down"></mat-icon>
        </span>
      </ng-container>
    </button> -->
    <a
      class="ml-auto"
      (click)="close()"
      [routerLink]="['/login']"
      color="primary"
      mat-button
      >Sair</a
    >
  </div>
</div>

<mat-menu #statusMenu="matMenu" xPosition="before" yPosition="below">
  <button
    (click)="setStatus(status)"
    *ngFor="let status of statuses; trackBy: trackById"
    mat-menu-item
  >
    <mat-icon [ngClass]="status.colorClass" [svgIcon]="status.icon"></mat-icon>
    <span>{{ status.label }}</span>
  </button>
</mat-menu>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon svgIcon="mat:business"></mat-icon>
    <span>Change Address</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:verified_user"></mat-icon>
    <span>Change Username</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:lock"></mat-icon>
    <span>Change Password</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:notifications_off"></mat-icon>
    <span>Disable Notifications</span>
  </button>
</mat-menu>
