import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PopoverRef } from "src/@vex/components/popover/popover-ref";
import { PopoverService } from "src/@vex/components/popover/popover.service";
import { CompaniesService } from "src/app/shared/services/companies.service";

@Component({
  selector: "vex-toolbar-share-dropdown",
  templateUrl: "./toolbar-share-dropdown.component.html",
  styleUrls: ["./toolbar-share-dropdown.component.scss"],
})
export class ToolbarShareDropdownComponent implements OnInit {
  currentCompany: any;
  loading: boolean = false;

  form: FormGroup = this.fb.group({
    email: ["", [Validators.required, Validators.email]],
  });
  constructor(
    private readonly fb: FormBuilder,
    private readonly snackBar: MatSnackBar,
    private readonly popOverCtrl: PopoverRef<ToolbarShareDropdownComponent>,
    private readonly companiesService: CompaniesService
  ) {
    this.companiesService.currentCompany$.subscribe((data: any) => {
      this.currentCompany = data;
    });
  }

  ngOnInit() {}

  async sendInvite() {
    this.loading = true;
    try {
      const response: any = await this.companiesService.shareCompany({
        email: this.form.value.email,
        empresa_id: this.currentCompany.id,
      });

      if (!response.success) {
        this.snackBar.open(response.message, "", {
          duration: 4000,
        });
        return;
      }

      this.popOverCtrl.close();

      this.snackBar.open(response.message, "", {
        duration: 4000,
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }
}
