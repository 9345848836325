import { OrmService } from "src/app/shared/services/orm.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BehaviorSubject, lastValueFrom } from "rxjs";

@Injectable()
export class ClientesService {
  clientesCountSubject: BehaviorSubject<number> = new BehaviorSubject(null);
  clientesCount$ = this.clientesCountSubject.asObservable();

  constructor(private http: HttpClient, private ormService: OrmService) {}

  async findById(id: string, tenant: string) {
    let result = await this.ormService
      .get("clientes", { _id: id }, { nome: 1 }, {}, tenant)
      .toPromise();

    if (result.success) {
      return result.data[0];
    } else {
      return result.errors;
    }
  }

  async create(body: any) {
    return await lastValueFrom(
      this.http.post(`${environment.BASE_URL}/partner`, body)
    );
  }

  async findAllPagination(page: number, pageSize: number) {
    let url = `${environment.BASE_URL}/partner/paginado?page=${page}`;

    if (pageSize) {
      url += `&pageSize=${pageSize}`;
    }

    return await lastValueFrom(this.http.get(url));
  }

  async getCount() {
    const response: any = await lastValueFrom(
      this.http.get(`${environment.BASE_URL}/partner/count`)
    );
    this.clientesCountSubject.next(response);
  }

  async delete(id: number) {
    return await lastValueFrom(
      this.http.delete(`${environment.BASE_URL}/partner/delete-partner/${id}`)
    );
  }

  async update(id: number, body: any) {
    const httpOptions = {
      headers: new HttpHeaders()
        .append("Content-Type", "application/json")
        .append("Access-Control-Allow-Origin", "*")
        .append("Access-Control-Allow-Headers", "*")
        .append("Access-Control-Allow-Credentials", "true"),
    };

    return await lastValueFrom(
      this.http.put(
        `${environment.BASE_URL}/partner/update-partner/${id}`,
        body,
        httpOptions
      )
    );
  }

  async listAll() {
    return await lastValueFrom(
      this.http.get(`${environment.BASE_URL}/partner/list-all`)
    );
  }
}
